const routers = [
  {
    path: "/",
    redirect: "/auth",
    auth: false,
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Auth/login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "Forgot",
        path: "/forgot",
        componentPath: "pages/Auth/forgot",
        name: "forgot",
        auth: false,
        exact: true,
      },
      {
        component: "ResetPassword",
        path: "/reset-password",
        componentPath: "pages/Auth/ResetPassword",
        name: "ResetPassword",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/dashboard",
    auth: false,
    name: "Dashboard",
    exact: false,
    childrens: [
      {
        component: "Dashboard",
        auth: false,
        exact: true,
        path: "/home",
        componentPath: "pages/Dashboard/index",
        name: "Dashboard",
      },
      {
        component: "Blogs",
        path: "/blogs",
        componentPath: "pages/Blogs",
        name: "Blogs",
        auth: false,
        exact: true,
      },
      {
        component: "AddBlog",
        auth: false,
        exact: true,
        path: "/blogs/addblog",
        componentPath: "pages/Blogs/AddBlog",
        name: "AddBlog",
      },
      {
        component: "AddBlog",
        path: "/blogs/editblog/:slug/:blogId",
        componentPath: "pages/Blogs/AddBlog",
        name: "EditBlog",
        auth: false,
        exact: true,
      },
      {
        component: "Settings",
        auth: false,
        exact: true,
        path: "/settings",
        componentPath: "pages/Settings",
        name: "Settings",
      },
      {
        component: "SendReports",
        path: "/sendreports",
        componentPath: "pages/SendReports",
        name: "SendReports",
        auth: false,
        exact: true,
      },
      {
        component: "Reports",
        path: "/reports",
        componentPath: "pages/Reports",
        name: "Reports",
        auth: false,
        exact: true,
      },
      {
        component: "SendReportsForm",
        auth: false,
        exact: true,
        path: "/sendreports/:bookingId",
        componentPath: "pages/SendReports/Form",
        name: "SendReportsForm",
      },
      {
        component: "PaymentTransaction",
        auth: false,
        exact: true,
        path: "/paymenttransaction",
        componentPath: "pages/PaymentTransaction",
        name: "PaymentTransaction",
      },
      {
        component: "Category",
        auth: false,
        exact: true,
        path: "/category",
        componentPath: "pages/Category",
        name: "Category",
      },
      {
        component: "Users",
        auth: false,
        exact: true,
        path: "/users",
        componentPath: "pages/Users",
        name: "Users",
      },
      {
        component: "NewsLetter",
        auth: false,
        exact: true,
        path: "/news-letter",
        componentPath: "pages/NewsLetter",
        name: "NewsLetter",
      },
      {
        component: "DiagnosticManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement",
        componentPath: "pages/DiagnosticManage",
        name: "DiagnosticManage",
      },
      {
        component: "AddTestManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/test/:type",
        componentPath: "pages/Add/AddTestManage",
        name: "AddTestManage",
      },
      {
        component: "AddTestManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/test/:type/:id",
        componentPath: "pages/Add/AddTestManage",
        name: "AddTestManage",
      },
      {
        component: "AddCategoryManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/category/:type",
        componentPath: "pages/Add/AddCategoryManage",
        name: "AddCategoryManage",
      },
      {
        component: "AddCategoryManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/category/:type/:id",
        componentPath: "pages/Add/AddCategoryManage",
        name: "AddCategoryManage",
      },
      {
        component: "AddSubCategoryManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/subcategory/:type",
        componentPath: "pages/Add/AddSubCategoryManage",
        name: "AddSubCategoryManage",
      },
      {
        component: "AddSubCategoryManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/subcategory/:type/:id",
        componentPath: "pages/Add/AddSubCategoryManage",
        name: "AddSubCategoryManage",
      },
      {
        component: "AddPackageManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/package/:type",
        componentPath: "pages/Add/AddPackageManage",
        name: "AddPackageManage",
      },
      {
        component: "AddPackageManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/package/:type/:id",
        componentPath: "pages/Add/AddPackageManage",
        name: "AddPackageManage",
      },
      {
        component: "AddConditionManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/condition/:type",
        componentPath: "pages/Add/AddConditionManage",
        name: "AddConditionManage",
      },
      {
        component: "AddConditionManage",
        auth: false,
        exact: true,
        path: "/diagnosticmanagement/condition/:type/:id",
        componentPath: "pages/Add/AddConditionManage",
        name: "AddConditionManage",
      },
      {
        component: "CouponManage",
        auth: false,
        exact: true,
        path: "/couponmanagement",
        componentPath: "pages/CouponManage",
        name: "CouponManage",
      },
      {
        component: "AddCouponManage",
        auth: false,
        exact: true,
        path: "/couponmanagement/coupon/:type",
        componentPath: "pages/Add/AddCouponManage",
        name: "AddCouponManage",
      },
      {
        component: "AddCouponManage",
        auth: false,
        exact: true,
        path: "/couponmanagement/coupon/:type/:id",
        componentPath: "pages/Add/AddCouponManage",
        name: "AddCouponManage",
      },
    ],
  },
];

export default routers;
